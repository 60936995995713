import React from 'react'
import { Link } from 'gatsby'
import { Box, Heading, Button } from 'theme-ui'
import Section from '@components/Section'

/**
 * Shadow me to add your own content
 */

const styles = {
  heading: {
    mb: 4,
    span: {
      color: `omegaDark`
    }
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`,
    width: `5/6`
  }
}

export default () => (
  <>
    <Section>
      <Heading variant='h1' sx={styles.heading}>
        Bulgaristan'dan <span>acil</span> ve <span>garantili</span> vize alın!
      </Heading>
      <Heading variant='h3' sx={styles.running}>
        Bizim danışmanlığımızda vize başvurunuza en iyi şekilde hazırlanın. Sizin yerinize süreç takibini biz yapalım. 
      </Heading>
    </Section>
    <Box variant='buttons.group' sx={styles.buttons}>
      <Button as={Link} to='/bulgaristan'>
        Detaylar
      </Button>
      <Button variant='white' as={Link} to='/iletisim'>
        Bize Ulaşın
      </Button>
    </Box>
  </>
)
